<template>
  <div class="campaign-list-table">
    <div v-if="loader && localOrders.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
          <!--begin::Table head-->
          <thead>
            <!--begin::Table row-->
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th class="min-w-100px" v-if="!advertiserId">Anunciante</th>
              <th class="min-w-100px">Valor</th>
              <th class="min-w-70px">Data de criação</th>
              <th class="min-w-70px">Status</th>
              <th class="min-w-70px">Status NFSe</th>
              <th class="min-w-70px">Ativo</th>
              <th class="min-w-70px">Ações</th>
            </tr>
            <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="fw-semibold text-gray-600">
            <!--begin::Table row-->
            <tr v-for="(order, index) in localOrders" :key="index">
              <td v-if="!advertiserId">
                <span>
                  <router-link
                    :to="{ name: 'advertisers.show', params: { id: order.advertiser.id } }"
                  >
                    {{ order.advertiser.name }}
                  </router-link>
                </span>
              </td>
              <td>
                <span>{{ order.amount/100 | formatMoney(order.advertiser.currency) }}</span>
              </td>
              <td>
                <span>{{ order.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </td>
              <td>
                <span>{{ order.payment_status | formatPaymentStatus }}</span>
              </td>
              <td>
                <span>{{ order.nfse_status | formatNFSeStatus }}</span>
              </td>
              <td>
                <span>{{ order.is_active ? 'Sim' : 'Não' }}</span>
              </td>
              <td>
                <span>
                  <Button
                    class="btn btn-sm btn-primary"
                    v-b-modal="`order-detail-modal-${order.id}`"
                  >
                    <i class="fas fa-eye px-0"></i>
                  </Button>
                </span>
                <OrderDetailModal
                  :orderId="order.id"
                />
              </td>
            </tr>
            <!--end::Table row-->
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <!--end::Table-->
      <div class="text-center mt-4">
        <Button
          class="btn-primary"
          :loading="loader"
          @click="loadMore"
          v-if="isLoadMoreVisible"
        >
          Carregar mais
        </Button>
      </div>
    </template>
  </div>
</template>

<script>

import OrdersService from '@/modules/orders/services/orders-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import OrderDetailModal from '@/components/orders/OrderDetailModal'

export default {
  name: 'OrderListTable',
  props: ['advertiserId', 'orders'],
  components: {
    Button,
    Loader,
    OrderDetailModal
  },
  data () {
    return {
      localOrders: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.orders) {
      this.localOrders = this.orders
      this.isLoadMoreVisible = false
    } else {
      this.getOrders()
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    }
  },
  methods: {
    /**
     * Get orders
     */
    async getOrders () {
      if (!this.advertiserId) return false

      this.loader = true
      try {
        const orders = await OrdersService.getOrders({
          advertiser_id: this.advertiserId,
          page: this.page
        })
        this.localOrders = [...this.localOrders, ...orders.data]
        this.isLoadMoreVisible = orders.meta.current_page !== orders.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getOrders()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localOrders = []
      this.getOrders()
    }
  }
}
</script>
