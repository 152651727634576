<template>
  <div>
    <Button
      class="btn-success"
      @click="impersonate"
      :loading="loader"
    >
      Iniciar impersonate
    </Button>
  </div>
</template>

<script>

import UsersService from '@/modules/users/services/users-service'

import Button from '@/components/common/Button/Button'

export default {
  props: ['userId', 'page'],
  name: 'ImpersonateButton',
  components: {
    Button
  },
  data () {
    return {
      loader: false
    }
  },
  methods: {
    /**
     * Submit impersonate
     */
    async impersonate () {
      this.loader = true
      try {
        const response = await UsersService.impersonate(this.userId)
        const token = response.access_token
        const originalToken = localStorage.getItem('access_token')
        let url = `${process.env.VUE_APP_BASE_URL}?impersonate=${token}&original_token=${originalToken}`
        if (this.page) url += `&page=${this.page}`
        window.open(url, '_blank')
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
