<template>
  <div class="campaign-list-table">
    <div v-if="loader && localCampaigns.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <div class="table-responsive">
        <!--begin::Table-->
        <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
          <!--begin::Table head-->
          <thead>
            <!--begin::Table row-->
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th class="min-w-100px" v-if="!advertiserId">Anunciante</th>
              <th class="min-w-100px">Nome</th>
              <th class="min-w-100px">Tipo</th>
              <th class="min-w-70px">Status</th>
              <th class="min-w-70px">Início</th>
              <th class="min-w-70px">Término</th>
              <th class="min-w-70px">Valor</th>
              <th class="min-w-70px">Gasto</th>
              <th class="min-w-70px">CTR</th>
              <th class="min-w-70px">Ações</th>
            </tr>
            <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="fw-semibold text-gray-600">
            <!--begin::Table row-->
            <tr v-for="(campaign, index) in localCampaigns" :key="index">
              <td v-if="!advertiserId">
                <span>
                  <router-link
                    :to="{ name: 'advertisers.show', params: { id: campaign.advertiser.id } }"
                  >
                    {{ campaign.advertiser.name }}
                  </router-link>
                </span>
              </td>
              <td>
                <span>{{ campaign.name }}</span>
              </td>
              <td>
                <span>{{ campaign.type | formatCampaignType }}</span>
              </td>
              <td>
                <span>{{ campaign.status | formatStatus }}</span>
              </td>
              <td>
                <span>{{ campaign.start_date | formatDate }}</span>
              </td>
              <td>
                <span>{{ campaign.end_date | formatDate }}</span>
              </td>
              <td>
                <span>{{ campaign.amount/100 | formatMoney(campaign.advertiser.currency) }}</span>
              </td>
              <td>
                <span>{{ campaign.spending/100 | formatMoney(campaign.advertiser.currency) }}</span>
              </td>
              <td>
                <span>{{ campaign.ctr }}%</span>
              </td>
              <td>
                <span>
                  <router-link
                    :to="{ name: 'campaigns.show', params: { id: campaign.id } }"
                    class="btn btn-sm btn-primary"
                  >
                    <i class="fas fa-eye px-0"></i>
                  </router-link>
                </span>
              </td>
            </tr>
            <!--end::Table row-->
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <!--end::Table-->
      <div class="text-center mt-4">
        <Button
          class="btn-primary"
          :loading="loader"
          @click="loadMore"
          v-if="isLoadMoreVisible"
        >
          Carregar mais
        </Button>
      </div>
    </template>
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'CampaignListTable',
  props: ['advertiserId', 'campaigns'],
  components: {
    Button,
    Loader
  },
  data () {
    return {
      localCampaigns: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.campaigns) {
      this.localCampaigns = this.campaigns
      this.isLoadMoreVisible = false
    } else {
      this.getCampaigns()
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    }
  },
  methods: {
    /**
     * Get campaigns
     */
    async getCampaigns () {
      if (!this.advertiserId) return false

      this.loader = true
      try {
        const campaigns = await CampaignsService.getCampaigns({
          advertiser_id: this.advertiserId,
          page: this.page
        })
        this.localCampaigns = [...this.localCampaigns, ...campaigns.data]
        this.isLoadMoreVisible = campaigns.meta.current_page !== campaigns.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getCampaigns()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localCampaigns = []
      this.getCampaigns()
    }
  }
}
</script>
